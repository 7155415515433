const app = {
  ssrAppUrl: process.env.SSR_APP_URL,
  imagesHostUrl: process.env.NEXT_PUBLIC_IMAGES_HOST_URL,
  filesHostUrl: `${process.env.NEXT_PUBLIC_FILE_HOST_URL}/`,
  apiRouteCacheLifetime: process.env.API_ROUTE_CACHE_LIFETIME,
  logPerformance: process.env.PERFORMANCE_LOGGING === 'true',
  maxReloadRetries: Number(process.env.NEXT_PUBLIC_MAX_RELOAD_RETRIES) || 1,
  withPrefetch: false,
};

export default app;
