// eslint-disable-next-line max-classes-per-file
class IAuth {
  shopId?: number;

  userId?: number;

  isStealthy?: boolean;

  cartId?: number;
}

export class Auth extends IAuth {
  private observers: ((auth: this) => void)[] = [];

  set({ shopId, cartId, userId, isStealthy }: IAuth) {
    this.shopId = shopId;
    this.userId = userId;
    this.cartId = cartId;
    this.isStealthy = isStealthy;
    this.observers.forEach((callback) => callback(this));
  }

  isLoggedIn = () => !!this.userId && !this.isStealthy;

  clear() {
    this.userId = undefined;
    this.cartId = undefined;
  }

  subscribe(callback: (auth: this) => void) {
    this.observers.push(callback);
  }
}

export default new Auth();
