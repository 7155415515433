import { useState, useEffect, ReactNode, useContext } from 'react';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';

import {
  getUserRoute,
  createAccountRoute,
  changeEmailRoute,
  requestEmailChangeRoute,
  changePasswordRoute,
  resetPasswordRoute,
  resendActivationLinkRoute,
  logoutRoute,
  loginRoute,
} from 'apiRoutes/users';
import { updateAddressRoute } from 'apiRoutes/users/addresses';
import { updateSessionVarsRoute } from 'apiRoutes/session';

import { bypassHeaders } from 'consts/session';
import { ConfigContext } from '@providers/ConfigProvider';
import { SessionContext } from '@providers/SessionProvider';
import getRefererType from 'utils/getRefererType';
// import { init as initAnalytics } from 'utils/analytics';
import useFetch from 'hooks/useFetch';

import { UserContext, UserContextType } from './UserProvider.context';

type Props = {
  connectingIp: string;
  children: ReactNode;
};

const getPurifiedPath = (path: string, query: ParsedUrlQuery): string => {
  const forbiddenParams = ['gclid', 'ceneo_cid', 'ceneo_std', 'ceneo_spo', 'utm_source', 'utm_medium'];

  return forbiddenParams.reduce(
    (acc, curr) => acc.replace(`&${curr}=${query[curr]}`, '').replace(`${curr}=${query[curr]}&`, ''),
    path
  );
};

const UserProvider = ({ connectingIp, children }: Props): JSX.Element => {
  const { auth } = useContext(SessionContext);
  const [userData, setUserData] = useState<UserContextType['userData']>();
  const [getUser] = useFetch(getUserRoute);
  const [logoutMutation] = useFetch(logoutRoute, { options: { headers: bypassHeaders } });
  const [login] = useFetch(loginRoute, { options: { headers: bypassHeaders } });
  const [register] = useFetch(createAccountRoute);
  const [updateAddress] = useFetch(updateAddressRoute);
  const [resendActivationLink] = useFetch(resendActivationLinkRoute);
  const [resetPassword] = useFetch(resetPasswordRoute);
  const [changePassword] = useFetch(changePasswordRoute);
  const [requestEmailChangeMutation] = useFetch(requestEmailChangeRoute);
  const [changeEmailMutation] = useFetch(changeEmailRoute);
  const [updateSessionVars] = useFetch(updateSessionVarsRoute);
  const router = useRouter();
  const { appUrl } = useContext(ConfigContext);
  const { Provider } = UserContext;

  const fetchUser: UserContextType['fetchUser'] = async () => {
    if (auth.isLoggedIn()) {
      const result = await getUser();

      if (result.data) {
        setUserData(result?.data?.user);
      } else {
        router.reload();
      }

      return result;
    }

    setUserData(undefined);

    return undefined;
  };

  const logout: UserContextType['logout'] = async () => {
    const result = await logoutMutation();
    setUserData(undefined);

    return result;
  };

  const editUser: UserContextType['editUser'] = async ({ firstName, lastName, phone }) => {
    if (userData) {
      await updateAddress({ first_name: firstName, last_name: lastName, phone, id: userData.id });
      await fetchUser();
    }
  };

  const requestEmailChange: UserContextType['requestEmailChange'] = async ({ newEmail }) => {
    if (userData) {
      return requestEmailChangeMutation({ newEmail });
    }

    return undefined;
  };

  const editEmail: UserContextType['editEmail'] = async ({ token }) => {
    if (userData) {
      const mutation = await changeEmailMutation({ token });
      await fetchUser();
      return mutation;
    }

    return undefined;
  };

  useEffect(() => {
    const init = async () => {
      const initGclid = router.query.gclid as string | undefined;
      const initCeneoCid = router.query.ceneo_cid as string | undefined;
      const initClid = initGclid || initCeneoCid || document.referrer;
      const refType = getRefererType(router.query, document.referrer);

      await updateSessionVars(
        {
          sessionVars: {
            clid: refType !== 'unknown' || !document.referrer.includes(appUrl) ? initClid : undefined,
            referer: refType,
            entry_page: getPurifiedPath(router.asPath, router.query),
            clientData: {
              height: window.screen.height,
              width: window.screen.width,
              userAgent: navigator.userAgent,
              ip: connectingIp,
            },
          },
        },
        {},
        true
      );
      // initAnalytics(initClid);
    };
    init();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Provider
      value={{
        userData,
        register,
        login,
        logout,
        fetchUser,
        editUser,
        editEmail,
        resendActivationLink,
        resetPassword,
        changePassword,
        requestEmailChange,
      }}
    >
      {children}
    </Provider>
  );
};

export default UserProvider;
