import { createContext } from 'react';

import { UserData } from 'types/UserData';
import { SessionVars } from 'types/Session';
import {
  getUserRoute,
  createAccountRoute,
  changeEmailRoute,
  requestEmailChangeRoute,
  changePasswordRoute,
  resetPasswordRoute,
  resendActivationLinkRoute,
  logoutRoute,
  loginRoute,
} from 'apiRoutes/users';

import { GetVariables } from 'utils/apiRoute';
import { FetchResult } from 'hooks/useFetch';

export type UserContextType = {
  userData?: UserData;
  sessionVars?: SessionVars;
  fetchUser: () => Promise<FetchResult<typeof getUserRoute> | undefined>;
  register: (params: GetVariables<typeof createAccountRoute>) => Promise<FetchResult<typeof createAccountRoute>>;
  login: (params: GetVariables<typeof loginRoute>) => Promise<FetchResult<typeof loginRoute>>;
  logout: () => Promise<FetchResult<typeof logoutRoute>>;
  resendActivationLink: (
    params: GetVariables<typeof resendActivationLinkRoute>
  ) => Promise<FetchResult<typeof resendActivationLinkRoute>>;
  editUser: ({ firstName, lastName }: Pick<UserData, 'firstName' | 'lastName' | 'phone'>) => Promise<void>;
  requestEmailChange: ({
    newEmail,
  }: GetVariables<typeof requestEmailChangeRoute>) => Promise<FetchResult<typeof requestEmailChangeRoute> | undefined>;
  editEmail: ({
    token,
  }: GetVariables<typeof changeEmailRoute>) => Promise<FetchResult<typeof changeEmailRoute> | undefined>;
  resetPassword: (
    variables: GetVariables<typeof resetPasswordRoute>
  ) => Promise<FetchResult<typeof resetPasswordRoute>>;
  changePassword: (
    params: GetVariables<typeof changePasswordRoute>
  ) => Promise<FetchResult<typeof changePasswordRoute>>;
};

const defaultHandler = async () => ({
  data: undefined,
  error: undefined,
  loading: false,
  cacheHit: false,
});

export const UserContext = createContext<UserContextType>({
  userData: undefined,
  register: defaultHandler,
  resendActivationLink: defaultHandler,
  login: defaultHandler,
  logout: defaultHandler,
  fetchUser: defaultHandler,
  editUser: async () => {},
  editEmail: defaultHandler,
  requestEmailChange: defaultHandler,
  resetPassword: defaultHandler,
  changePassword: defaultHandler,
});
