import { ReactNode, useRef, useState } from 'react';

import { Session, SessionVars } from 'types/Session';
import auth from 'auth';
import { getSessionVarsRoute, updateSessionVarsRoute } from 'apiRoutes/session';
import useFetch from 'hooks/useFetch';

import { SessionContext, SessionContextType } from './SessionProvider.context';

type Props = {
  children: ReactNode;
};

const SessionProvider = ({ children }: Props): JSX.Element => {
  const [getSessionVars] = useFetch(getSessionVarsRoute);
  const [updateSessionVars] = useFetch(updateSessionVarsRoute);
  const [sessionKey, setSessionKey] = useState(0);
  const [sessionVars, setSessionVars] = useState<SessionVars>();
  const sessionRef = useRef<boolean | undefined>(!!sessionKey);

  const bumpSessionKey = () => {
    setSessionKey((currentSessionIndex) => currentSessionIndex + 1);
    sessionRef.current = true;
  };

  const checkIfSessionIsRefreshed = async (trailingExecution = false): Promise<boolean> => {
    if (trailingExecution) {
      await new Promise((resolveTimeout) => {
        setTimeout(resolveTimeout, 10);
      });
    }
    return new Promise((resolve) => {
      if (sessionRef.current) {
        resolve(true);
      } else {
        resolve(checkIfSessionIsRefreshed(true));
      }
    });
  };

  const refreshSession = (session: Session) => {
    auth.set(session.auth);
    setSessionVars(session.sessionVars);
    bumpSessionKey();
  };

  const getSessionVar: SessionContextType['getSessionVar'] = (key) => sessionVars?.[key];

  const setSessionVar: SessionContextType['setSessionVar'] = async (key, value) => {
    const result = await updateSessionVars({ sessionVars: { [key]: value } });
    setSessionVars(result.data?.session.sessionVars || {});
    return result;
  };

  const refreshSessionVars: SessionContextType['refreshSessionVars'] = async () => {
    const result = await getSessionVars();
    setSessionVars(result.data?.sessionVars || {});
    return result;
  };

  return (
    <SessionContext.Provider
      value={{
        auth,
        sessionVars,
        sessionKey,
        isLoggedIn: auth.isLoggedIn,
        checkIfSessionIsRefreshed,
        setSessionVars,
        refreshSession,
        bumpSessionKey,
        getSessionVar,
        setSessionVar,
        refreshSessionVars,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export default SessionProvider;
