import { createContext } from 'react';

import SetState from 'types/SetState';
import { Session, SessionVars } from 'types/Session';
import { getSessionVarsRoute, updateSessionVarsRoute } from 'apiRoutes/session';
import auth, { Auth } from 'auth';
import { FetchResult } from 'hooks/useFetch';

export type SessionContextType = {
  auth: Auth;
  sessionKey: number;
  sessionVars?: SessionVars;
  setSessionVars: SetState<SessionVars | undefined>;
  refreshSession: (session: Session) => void;
  isLoggedIn: () => boolean;
  bumpSessionKey: () => void;
  checkIfSessionIsRefreshed: () => Promise<boolean>;
  getSessionVar: <T extends keyof SessionVars>(key: T) => SessionVars[T] | undefined;
  setSessionVar: <T extends keyof SessionVars>(
    key: T,
    value: SessionVars[T]
  ) => Promise<FetchResult<typeof updateSessionVarsRoute> | null>;
  refreshSessionVars: () => Promise<FetchResult<typeof getSessionVarsRoute>>;
};

const defaultHandler = async () => ({
  data: undefined,
  error: undefined,
  loading: false,
  cacheHit: false,
});

export const SessionContext = createContext<SessionContextType>({
  auth,
  sessionKey: 0,
  isLoggedIn: () => false,
  setSessionVars: () => {},
  refreshSession: () => {},
  bumpSessionKey: () => {},
  checkIfSessionIsRefreshed: async () => false,
  getSessionVar: () => undefined,
  setSessionVar: defaultHandler,
  refreshSessionVars: defaultHandler,
});
