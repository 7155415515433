import { parse } from 'querystring';

import config from 'config';

const retryCountKey = 't';

const tryReloadPage = () => {
  const queryParams = parse(window.location.search.replace('?', ''));
  const retryCount = Number(queryParams[retryCountKey] || '');

  if (retryCount < config.maxReloadRetries) {
    window.location.href = `/?${new URLSearchParams({ [retryCountKey]: `${retryCount + 1}` })}`;
  }
};

export default tryReloadPage;
